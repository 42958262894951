import React from 'react';
import Section from '../Section/Section';
import * as styles from './features.module.scss';
import { Link } from 'gatsby';
import Arrow from '../../../../assets/images/svg/arrow.svg';
import { CONS_LIST, PROS_LIST } from './feature-list';
import { classNames } from '../../../../utils';
import { Icon } from '../../../components/Icon/Icon';


export default function Features() {
  return (
    <Section className={ styles.features } heading="Features" isHiddenHeading>
      <div className={ styles.featuresBody }>
        <MainFeature icon="comet" heading="Lightweight" link="Get Started" to="/guides/getting-started/">
          You can start with the default small package that is about 70Kb (24Kb gzipped),
          including Indentation, History (Undo/Redo), and Shortcuts extensions.
        </MainFeature>

        <MainFeature icon="satellite" heading="Accessibility Friendly" link="Browse Themes" to="/guides/themes/">
          By using a &quot;contenteditable&quot; element,
          screen readers are able to read the current and selected code for visually impaired users,
          which promises your site accessibility.
        </MainFeature>

        <MainFeature icon="orbit" heading="Modular and Extensible" link="Browse Components" to="/components/">
          The editor functionality can be extended by adding modular extensions.
          You can build your custom editor by picking them out or creating your own extensions.
        </MainFeature>

        <MainFeature icon="rocket" heading="Mobiles and IE" link="View Documents" to="/enhancement/defining-languages/">
          The UX is not perfect, but the editor works on mobile devices thanks to the &quot;contenteditable&quot;.
          It also works on the IE11 with some limitations.
        </MainFeature>
      </div>

      <ul className={ styles.featureList }>
        { PROS_LIST.map( ( desc, index ) => <FeatureItem key={ index }>{ desc }</FeatureItem> ) }
        { CONS_LIST.map( ( desc, index ) => <FeatureItem key={ index } cons>{ desc }</FeatureItem> ) }
      </ul>
    </Section>
  );
}

function MainFeature( { icon, heading, children, to = '/', link } ) {
  return (
    <section className={ styles.feature }>
      <header className={ styles.featureHeader }>
        <Icon name={ icon }/>
        <h3>{ heading }</h3>
      </header>
      <div>
        { children }
      </div>

      {/*<Link to={ to } className={ styles.featureLink }>*/}
      {/*  <Arrow/>*/}
      {/*  <span>*/}
      {/*    { link }*/}
      {/*  </span>*/}
      {/*</Link>*/}
    </section>
  );
}

function FeatureItem( { cons, children } ) {
  return (
    <li className={ classNames( styles.featureItem, cons && styles.featureItemCons ) }>
      <Icon name={ cons ? 'close' : 'check' } />

      <span className={ styles.featureItemDesc }>
        { children }
      </span>
    </li>
  );
}