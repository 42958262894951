import React from 'react';
import Section from '../Section/Section';
import * as styles from './examples.module.scss';
import { Editor } from '../../../components/Editor/Editor';
import { LinkButton } from '../../../components/LinkButton/LinkButton';
import { OPTIONS_FOR_DEFAULT_PACKAGE, SHORTCUTS } from './constants';
import { classNames } from '../../../../utils';
import { Keyboard } from '../../../components/Keyboard/Keyboard';


export default function Examples() {
  return (
    <Section heading="Examples" className={ styles.examples } isNarrow>
      <section className={ styles.examplesSection }>
        <h3>Default</h3>
        <p>
          Only essential components, Dialog, Indentation, History, and Shortcut, are included,
          which would be enough for editing small code snippet.
        </p>
        <Editor
          id="example-default"
          options={ OPTIONS_FOR_DEFAULT_PACKAGE }
          sourceId="short"
          language="js"
        />
      </section>

      <section className={ styles.examplesSection }>
        <h3>Full Featured</h3>
        <p>All extensions are included.</p>

        <Editor id="example-full-featured" sourceId="middle" language="ts"/>

        <div role="presentation" aria-hidden="true">
          <div className={ classNames( styles.examplesDecorationObject, styles.examplesDecorationGrad1 ) }>
          </div>
        </div>

        <table className={ styles.examplesTable }>
          <tbody>
            { SHORTCUTS.map( ( shortcut, index ) => (
              <tr key={ index }>
                <th><Keyboard keys={ shortcut[ 0 ] } /></th>
                <td>{ shortcut[ 1 ] }</td>
              </tr>
            ) ) }
          </tbody>
        </table>
      </section>

      <footer className={ styles.examplesFooter }>
        <LinkButton to="/extensions/">Browse Extensions</LinkButton>
      </footer>
    </Section>
  );
}