export const OPTIONS_FOR_DEFAULT_PACKAGE = {
  activeLine     : false,
  autoClose      : false,
  bracketMatching: false,
  comment        : false,
  guide          : false,
  gutter         : false,
  jump           : false,
  resize         : false,
  search         : false,
  toolbar        : false,
  minHeight      : '8em',
};

export const SHORTCUTS = [
  [ [ 'Z', true ], 'Undo changes' ],
  [ [ 'Z', true, true ], 'Redo changes' ],
  [ [ 'F', true ], 'Activate the Search toolbar' ],
  [ [ 'F', true, true ], 'Activate the Replace toolbar' ],
  [ [ 'G', true ], 'Activate the Jump toolbar' ],
  [ [ 'C', true ], 'Copy the current line when the selection is collapsed' ],
  [ [ 'X', true ], 'Cut the current line when the selection is collapsed' ],
  [ [ '/', true ], 'Comment out and uncomment selected lines with line comments' ],
  [ [ '/', true, true ], 'Comment out and uncomment selected lines with block comments' ],
  [ [ '↓', true ], 'Scroll down' ],
  [ [ '↑', true ], 'Scroll up' ],
];
