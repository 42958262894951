import React from 'react';
import { RyuseiCode, Extensions, javascript, typescript } from '@ryusei/code';
import { classNames } from '../../../utils';
import * as styles from './editor.module.scss';
import { OPTIONS_FOR_DEFAULT_PACKAGE } from '../../scenes/Home/Examples/constants';
import { graphql, StaticQuery } from 'gatsby';


RyuseiCode.register( [ javascript(), typescript() ] );
RyuseiCode.compose( Extensions );

export class Editor extends React.Component {
  constructor( props ) {
    super( props );
    this.ref        = React.createRef();
    this.ryuseiCode = new RyuseiCode( this.getOptions() );
  }

  componentDidMount() {
    setTimeout( () => {
      this.ryuseiCode.apply( this.ref.current.firstElementChild );

      const { toolbarId } = this.props;

      if ( toolbarId ) {
        this.ryuseiCode.Editor.invoke( 'Toolbar', 'show', toolbarId );
      }
    }, 100 );
  }

  componentWillUnmount() {
    this.ryuseiCode.destroy();
  }

  getOptions() {
    const { id, language, defaults } = this.props;
    let { options = {} } = this.props;

    if ( defaults ) {
      options = Object.assign( {}, OPTIONS_FOR_DEFAULT_PACKAGE, options );
    }

    options.id            = options.id || id;
    options.language      = options.language || language;
    options.monospaceFont = options.monospaceFont || `'Ryusei Mono', monospace`;

    return options;
  }

  render() {
    const { value, sourceId } = this.props;

    if ( sourceId ) {
      return this.renderSource();
    }

    return this.renderWrapper( this.ryuseiCode.html( value || '' ) );
  }

  renderSource() {
    const { sourceId, options = {} } = this.props;
    const id = this.props.id || options.id || sourceId;

    return <StaticQuery
      query={ graphql`
        query {
          allRyuseiCode {
            edges {
              node {
                html
                id
              }
            }
          }
        }
      ` }
      render={
        ( { allRyuseiCode: { edges } } ) => {
          const edge = edges.find( edge => edge.node.id === sourceId );
          const html = edge.node.html || '<pre></pre>';

          return this.renderWrapper( html.replace( /__RYUSEI_CODE_ID__/g, id ) );
        }
      }
    />
  }

  renderWrapper( html ) {
    const { className, theme } = this.props;

    return (
      <div
        ref={ this.ref }
        className={ classNames( 'ryuseicode-wrapper', styles.editor, className, theme ) }
        dangerouslySetInnerHTML={ { __html: html } }
      />
    );
  }
}