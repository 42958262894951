import React from 'react';
import Comet from '../../../assets/images/svg/comet.svg';
import Cassiopeia from '../../../assets/images/svg/cassiopeia.svg';
import Orbit from '../../../assets/images/svg/orbit.svg';
import Rocket from '../../../assets/images/svg/rocket.svg';
import Arrow from '../../../assets/images/svg/arrow.svg';
import Check from '../../../assets/images/svg/check.svg';
import Close from '../../../assets/images/svg/close.svg';
import Satellite from '../../../assets/images/svg/satellite.svg';
import Saturn from '../../../assets/images/svg/saturn.svg';


const ICONS = {
  comet     : Comet,
  cassiopeia: Cassiopeia,
  orbit     : Orbit,
  rocket    : Rocket,
  arrow     : Arrow,
  check     : Check,
  close     : Close,
  satellite : Satellite,
  saturn    : Saturn,
};

export function Icon( { name } ) {
  if ( ICONS[ name ] ) {
    return React.createElement( ICONS[ name ] );
  }

  return null;
}