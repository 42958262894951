export const PROS_LIST = [
  'Highlighting the current line',
  'Line numbers',
  'Auto closing paired characters',
  'Undoing and redoing code changes',
  'Increasing and decreasing the indent level of selected lines',
  'Commenting out selected lines with line or block comments by shortcuts',
  'Highlighting matched brackets',
  'Search and replace toolbar',
  'Finding words in match case, whole word and regexp modes',
  'Jump toolbar to go to the specific line',
  'Resizing the editor size by drag',
  'Custom dialog',
  'Custom context menus',
];

export const CONS_LIST = [
  'Text wrapping',
  'Code hint',
  'Huge code over 20,000 lines',
];