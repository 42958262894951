// extracted by mini-css-extract-plugin
export var header = "header-module--header--FKj3j";
export var headerContent = "header-module--header-content--1tClA";
export var headerHeading = "header-module--header-heading--3PSbp";
export var headerIntro = "header-module--header-intro--3crGv";
export var headerActions = "header-module--header-actions--17v6s";
export var headerEditor = "header-module--header-editor--18cVe";
export var headerText = "header-module--header-text--bbt9P";
export var headerDecorationObject = "header-module--header-decoration-object--2q_KX";
export var headerDecorationGrad1 = "header-module--header-decoration-grad1--2KISC";
export var headerDecorationGrad2 = "header-module--header-decoration-grad2--p3CDl";