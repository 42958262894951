import React from 'react';
import * as styles from './keyboard.module.scss';


export const MODIFIER_KEYS = {
  default: [ 'Ctrl', 'Shift', 'Alt' ],
  mac    : [ '⌘', '⇧', '⌥' ],
};

export function Keyboard( { keys, isMac } ) {
  const modifiers = MODIFIER_KEYS[ isMac ? 'mac' : 'default' ];
  const keyNames  = keys.slice( 1 )
    .map( ( use, index ) => use && modifiers[ index ] )
    .filter( Boolean )
    .concat( keys[ 0 ] );

  if ( keyNames.length === 1 ) {
    return <kbd className={ styles.keyboardInner }>{ keyNames[ 0 ] }</kbd>;
  }

  if ( keyNames.length > 1 ) {
    return (
      <kbd className={ styles.keyboardOuter }>
        { keyNames.map( ( key, index ) => (
          <React.Fragment key={ index }>
            <kbd className={ styles.keyboardInner }>
              { key }
            </kbd>
            { index !== keyNames.length - 1
            && <span className={ styles.keyboardPlus }>+</span> }
          </React.Fragment>
        ) ) }
      </kbd>
    );
  }

  return null;
}