import React from 'react';
import Section from '../Section/Section';
import { CAVEATS } from './caveats';
import * as styles from './about.module.scss';


export default function About() {
  return (
    <Section heading="About" isNarrow>
      <h3 className={ styles.aboutHeading }>About RyuseiCode</h3>

      <p>
        RyuseiCode is a lightweight, extensible and accessible code editor, written in TypeScript.
        Basically, this is created for desktop evergreen browsers, but works on mobile devices.
        It also works on IE11 without any polyfills.💪
      </p>

      <p>
        The RyuseiCode does not aim for a super robust code editor like <a href="https://microsoft.github.io/monaco-editor/" target="_blank" rel="noopener noreferrer">Monaco Editor</a>, but for good balance with simplicity and usability.
      </p>

      <p>
        This library uses <a href="https://light.ryuseijs.com/" target="_blank" rel="noopener noreferrer">RyuseiLight</a> as a syntax highlighter that is also written by me.
        Ryusei（流星, 流=flow, 星=star） is my personal project code,
        that means a shooting star in Japanese. 💫
      </p>

      <h3 className={ styles.aboutHeading }>Caveats</h3>

      <ul className={ 'list' }>
        { CAVEATS.map( ( caveat, index ) => (
          <li key={ index }>
            { caveat }
          </li>
        ) ) }
      </ul>
    </Section>
  );
}